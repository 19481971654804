<template>
  <b-card
    class="mb-0"
  >
    <div class="mb-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title mb-0 pt-50">
            Submitted Candidates {{ title }}</h4>
        </b-col>
        <b-col
          cols="12"
          md="6 ml-auto"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              class="ml-2 w-20"
              :to="{ name: 'candidates-new', params: { job_id: jobId }, query: {name: title} }"
            >
              Apply New
            </b-button>
            <b-button
              variant="outline-primary"
              class="ml-2 w-20"
              :to="{ name: 'jobs'}"
            >
              Back
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <vue-good-table
      :columns="columns"
      :rows="candidates"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Schedule Interview -->
        <span v-if="props.column.field === 'cellRendererInterviewDate'">
          <span
            v-for="option in props.row.interview_dates"
            :key="option"
          >
            <span
              :key="option"
              v-text="option"
            />
          </span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span
          v-else-if="props.column.field === 'action'"
          class="btn-action"
        >
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{ name: 'candidates-view', params: { id: props.row.id } }">
                <feather-icon icon="EyeIcon" />
                <span class="ml-50">View</span>
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'candidates-edit', params: { id: props.row.id } }">
                <feather-icon icon="Edit2Icon" />
                <span class="ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmDeleteRecord(props.row)">
                <feather-icon icon="TrashIcon" />
                <span class="ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-end flex-wrap">
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              first-number
              last-number
              align="right"
              :per-page="1"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BPagination, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import User from '@/js/user'
import Candidates from '@/js/candidates'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BButton,
    BDropdown,
    BDropdownItem,

    // vSelect,
  },
  data() {
    return {
      searchQuery: '',
      pageLength: 10,
      dir: false,
      jobId: null,
      candidates: [],
      count: 1,
      title: '',
      columns: [
        {
          label: '#',
          field: 'index',
          width: '100px',
        },
        {
          label: 'Name',
          field: 'prospect_name',
        },
        {
          label: 'supplier/referrer/employer/sub-vendor name',
          field: 'supplier_name',
        },
        {
          label: 'Submitted Date',
          field: 'submitted_date',
        },
        {
          label: 'Status',
          field: 'status',
          width: '150px',
        },
        {
          label: 'Interview Date & Time',
          field: 'cellRendererInterviewDate',
        },
        {
          label: 'Action',
          field: 'action',
          width: '150px',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$store.state.query.innerpage
      },
      set(val) {
        this.$store.state.query.innerpage = val
        Candidates.getCandidates(this, this.$route.params.job_id)
      },
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-success',
        Inactive    : 'light-danger',
        Terminated  : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.jobId = this.$route.params.job_id
    this.title = this.$route.query.name
    this.type = this.$route.query.type
    this.employerId = this.$route.query.employerId
    if (User.profile().job_exist) {
      Candidates.getCandidates(this, this.$route.params.job_id)
    } else {
      // if (User.profile().invoice_exist) {
      //   window.location.hash = '#/invoices'
      // } else if (User.profile().timesheet_exist) {
      //   window.location.hash = '#/timesheets'
      // } else {
      //   Auth.logout()
      // }
    }
  },
  methods: {
    confirmDeleteRecord(obj) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.prospect_name} from this job ${obj.title}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          Candidates.delete(this, obj.id)
        }
      })
    },
    candidateDeleted() {
      Candidates.getCandidates(this, this.$route.params.job_id)
      this.$swal({
        icon: 'success',
        title: 'Candidate Deleted!',
        text: 'Your Candidate has been deleted',
      })
    },
    candidateNotDeleted() {
      this.$swal({
        icon: 'warning',
        title: 'Candidate Not Deleted!',
        text: 'Your Candidate has been not deleted',
      })
    },
    updateSearchQuery() {
      this.$store.state.query.query = this.searchQuery
      this.currentPage = 1
    },
    setCandidates(data) {
      this.candidates = data.job_candidates
      this.title = data.title
      this.count = data.count
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
